<template>
  <div class="star-rating d-flex justify-center">
    <star-rating
      v-model="score"
      :increment="0.01"
      read-only
      :show-rating="false"
      :star-size="45"
      active-color="#5A88FF"
      rounded-corners
      inline
    ></star-rating>
  </div>
</template>
<script>
import styles from "@/assets/styles/utils/Variables.scss";

import StarRating from "vue-star-rating";

export default {
  data() {
    return {
      style: {},
      rating: 5,
    };
  },
  props: {
    score: {
      type: Number,
      default: 0,
    },
    color: {
      type: String,
      default: "grey",
    },
  },
  components: {
    StarRating,
  },
};
</script>

<style lang="scss">

</style>
