<template>

    <div class="modal-wrap lib-wrap">
      <section class="library modal">
        <img @click='$emit("close")' class='close' src="@/assets/images/icons/close-black.svg" alt="Fermer">
        <div class="modal-header">
            <h2>Bibliothèque</h2>
        </div>
        <div v-if='documents.length' class="library-content">
            <v-list class="library-list" dense two-line>
              <v-list-item
                :key="'item-'+index"
                v-for="(item, index) in documents"
              >
              <section class="list-item py-4">
                  <v-list-item-content>
                    <v-list-item-subtitle @click="handleOpenDoc(item.media_object.path, item.title)">
                      <span class="mr-4" v-if="item.chapter_object" :style="item.chapter_object ? 'background:'+item.chapter_object.color : null">{{item.chapter_object && item.chapter_object.number || null}}</span>
                      <span class="mr-4" v-if='item.isInfo' style='border:1px solid #E31C4A;background:#E31C4A'> 
                      <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 111.577 111.577" fill='white' xml:space="preserve"><g><path d="M78.962,99.536l-1.559,6.373c-4.677,1.846-8.413,3.251-11.195,4.217c-2.785,0.969-6.021,1.451-9.708,1.451c-5.662,0-10.066-1.387-13.207-4.142c-3.141-2.766-4.712-6.271-4.712-10.523c0-1.646,0.114-3.339,0.351-5.064c0.239-1.727,0.619-3.672,1.139-5.846l5.845-20.688c0.52-1.981,0.962-3.858,1.316-5.633c0.359-1.764,0.532-3.387,0.532-4.848c0-2.642-0.547-4.49-1.636-5.529c-1.089-1.036-3.167-1.562-6.252-1.562c-1.511,0-3.064,0.242-4.647,0.71c-1.59,0.47-2.949,0.924-4.09,1.346l1.563-6.378c3.829-1.559,7.489-2.894,10.99-4.002c3.501-1.111,6.809-1.667,9.938-1.667c5.623,0,9.962,1.359,13.009,4.077c3.047,2.72,4.57,6.246,4.57,10.591c0,0.899-0.1,2.483-0.315,4.747c-0.21,2.269-0.601,4.348-1.171,6.239l-5.82,20.605c-0.477,1.655-0.906,3.547-1.279,5.676c-0.385,2.115-0.569,3.731-0.569,4.815c0,2.736,0.61,4.604,1.833,5.597c1.232,0.993,3.354,1.487,6.368,1.487c1.415,0,3.025-0.251,4.814-0.744C76.854,100.348,78.155,99.915,78.962,99.536z M80.438,13.03c0,3.59-1.353,6.656-4.072,9.177c-2.712,2.53-5.98,3.796-9.803,3.796c-3.835,0-7.111-1.266-9.854-3.796c-2.738-2.522-4.11-5.587-4.11-9.177c0-3.583,1.372-6.654,4.11-9.207C59.447,1.274,62.729,0,66.563,0c3.822,0,7.091,1.277,9.803,3.823C79.087,6.376,80.438,9.448,80.438,13.03z"/></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g></svg>
                      </span>
                      <span class="mr-4" v-if='!item.chapter_object && !item.isInfo' style='border:1px solid black'> </span>
                      {{item.title}}
                        </v-list-item-subtitle>
                  </v-list-item-content>
              </section>
              </v-list-item>
          </v-list>
        </div>
        <!-- <div v-if='currentDoc !== null'>
          <PDFReader :src='currentDoc.src'/>
        </div> -->
    <transition name="slideright">
      <Document v-if='openDocument' :doc="currentDoc" @close="handleClose"/>
    </transition>
    </section>
    </div>
</template>

<script>
import Document from '@/components/Library_Document.vue'
// import PDFReader from '@/components/PDFReader/Document.vue'
import 'simplebar/dist/simplebar.min.css';
import {mapActions } from 'vuex'
export default {
  components: {
    Document,
    // PDFReader
  },
  data () {
    return {
      openDocument: false,
      currentDoc:null,
      documents:[]
    }
  },
  computed: {
    chapters () {
      return this.$store.getters['Chapter/chapters']
    }
  },
  async created () {
    await this.$store.dispatch('Chapter/getChapterList')
    const allDocuments = await this.getDocumentsList()
    if (this.$route.name === 'chapitre') {
      const chapterDocs = allDocuments.filter(doc => doc.chapter_object !== null)
      chapterDocs.forEach(item => {
        item.number = item.chapter_object.number
      })
      const document = chapterDocs.filter(doc => doc.number == this.$route.params.id)[0];
      // this.openDocument = true;
      this.handleOpenDoc(document.media_object.path, document.title)
    }
    else this.documents = allDocuments
  },
  methods:{
    ...mapActions("Library", ["getDocumentsList"]),
    handleOpenDoc(item, title) {
      let title_e = document.querySelector('.overlay');
      if(this.$route.name ==="chapitre") {
      if(title_e) title_e.style.display='none'
      }
      this.openDocument = true;
      this.currentDoc = {src: process.env.VUE_APP_AWS_S3_URL + '/'+item,
      title:title};
    },
    handleClose() {
      let title_e = document.querySelector('.overlay');
      
      if(this.$route.name === 'chapitre') {
      if(title_e) title_e.style.display='block'
        this.openDocument=false;
        this.$emit('close')
      }
      else {
        this.currentDoc = null
     this.openDocument = false
    }
    }
    
       },
}
</script>
