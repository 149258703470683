<template>
    <div class="modal-wrap">
        <v-container class="modal user-info--modal">
        <img @click='$emit("close")' class='close' src="@/assets/images/icons/close-black.svg" alt="Fermer">
        <div class="modal-header">
            <h2>Informations personnelles</h2>
        </div>
        <div class="modal-content">
            <p class="mb-0">Nom : <span class="bold">{{user.name}}</span> </p>
            <p class="mb-0">Prénom : <span class="bold">{{user.first_name}}</span> </p>
            <p class="mb-0">Statut : <span class="bold">{{statusAliases[user.onv_status]}}</span> </p>
            <p class="mb-0">Adresse : <span class="bold">{{user.adress ||'Non renseigné'}}</span> </p>
            <p class="mb-0">Code postal : <span class="bold">{{user.cedex ||'Non renseigné'}}</span> </p>
            <p class="mb-0">Ville : <span class="bold">{{user.city ||'Non renseigné'}}</span> </p>
            <p class="mb-0" v-if="user.structure">Structure : <span class="bold">{{user.structure}}</span> </p>
            <p class="mb-8">Nombre de lot : <span class="bold">{{user.nb_lots}}</span> </p>
            <!-- <p><span class="bold">Résidence :</span> {{data.residence}}</p>
            <p><span class="bold">Bailleur :</span> {{data.bailleur}}</p>
            <p><span class="bold">Ville :</span> {{data.ville}}</p>
            <p><span class="bold">Région :</span> {{data.region}}</p> -->
            <p class="mb-0 link" >
                <a @click="showCGU">Conditions générales d'utilisation du site</a>
            </p>
            <p class="link">
                <a @click="showConfidentiality">Politique de confidentialité</a>
            </p>
        </div>
        <v-btn class="logout" @click="handleLogout" color='error'>Déconnexion</v-btn>
    </v-container>
    </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  props: ['data'],
  data: () => ({
    statusAliases: { 1: 'Locataire', 2: 'Copropriétaire', 3: 'Autre' }
  }),
  computed: {
    ...mapGetters('Auth', ['user'])
  },
  methods:{
    ...mapActions('Auth', ['logout']),
    async handleLogout () {
      await this.logout();
      this.$router.push('/')
    },
    showCGU () {
        window.open(window.location.origin + '/cgu', '_blank');
    },
    showConfidentiality () {
        window.open(window.location.origin + '/privacy', '_blank');
    },

  }
}
</script>
<style lang="scss">
.user-info--modal {
    box-sizing: border-box;
 position: relative;
    box-sizing: border-box;
button {
    position: absolute;
    bottom: 20px;
    display: block;
box-sizing: border-box;
    width:70%;
    left: 50%;
    transform: translateX(-50%);
}
.modal-content {
    .bold {
        font-weight: bold;
    }
}
.link:hover {
    text-decoration: underline
}
}
</style>
