<template>
  <div @mouseover="isHover=true" @mouseout="isHover=false" class="menu-item" @click="$emit('change', chapter.number)" @touch="$emit('change', chapter.number)">
    <div :style="'background-color:' + chapter.color" class="index mr-4">
      <!-- <ProgressChapter :size='55' :chapter="chapter.id"></ProgressChapter> -->
      <span>0{{ chapter.number }}</span>
    </div>
    <div class="title">
      <span :class="isHover ? 'font-weight-bold' : null">{{ chapter.title }}</span>
    </div>
  </div>
</template>
<script>
import ProgressChapter from './Chapter_Progress_Circle.vue'
export default {
  components: { ProgressChapter },
  props: ['chapter'],
  data () {
    return {
      isHover: false,
      progressValue: 0
    }
  }
}
</script>
