<template>
  <div class="modal-wrap score-wrap">
    <v-container class="scores modal">
      <img
        @click="$emit('close')"
        class="close"
        src="@/assets/images/icons/close-black.svg"
        alt="Fermer"
      />

      <div class="scores-content">
        <div class="modal-header">
          <h2>Résultats</h2>
          <h3>
            {{
              selected
                ? "0" + selected.number + ". " + selected.title
                : "Bravo, vous progressez dans votre parcours !"
            }}
          </h3>
        </div>
        <div class="nothing-selected" v-if="!selected">
          <Rating :score="starRating" />
          <!-- <StarRating :starStyle="starStyle" :rating="starRating" :key="starRating" :isIndicatorActive="false"></StarRating> -->
          <span class="percentage">{{ globalCompletion }}%</span>
        </div>
        <div class="item-selected" v-else>
          <h3>Votre score pour cet épisode</h3>
          <div class="stars">
            <Rating :score="selected.score" :color="selected.color" />
            <!-- <StarRating :starStyle="starStyle" :rating="selected.score ? selected.score : 0" :key="selected.id" :isIndicatorActive="false"></StarRating> -->
          </div>
          <v-btn
            @click="handleClick(selected)"
            :style="
              !selected.isFinished ? 'border-width:3px;padding:10px' : null
            "
            :outlined="!selected.isFinished"
            color="primary"
            class="rounded-lg"
            >{{
              !selected.isFinished
                ? "Poursuivre l'épisode"
                : "Refaire l'épisode"
            }}</v-btn
          >
        </div>
        <div class="chapter-list-block">
          <p v-if="!selected">Consultez vos scores détaillés par épisode</p>
          <div class="chapter-list">
            <div
              v-for="chapter in chapters"
              :key="chapter.id"
              class="chapter-element"
            >
              <Progress :chapter="chapter.id" />
              <v-btn
                depressed
                x-small
                outlined
                fab
                :style="
                  selected && selected.id === chapter.id
                    ? 'background-color:' + chapter.color + ';border:none'
                    : null
                "
                @click="getChapterScore(chapter.id)"
              >
                0{{ chapter.number }}</v-btn
              >
            </div>
          </div>
        </div>
      </div>
    </v-container>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
// import StarRating from "vue-dynamic-star-rating";
import Rating from "@/components/Rating.vue";
import Progress from "@/components/Chapter_Progress_Circle";
export default {
  props: ["data"],
  components: {
    // StarRating,
    Progress,
    Rating,
  },
  watch: {
    // selected: function (newVal) {
    //   this.current = this.data[newVal - 1]
    //   this.current.index = newVal
    // }
  },
  data () {
    return {
      selected: null,
      current: null,
      starRating: 0,
      globalCompletion: 0,
    };
  },
  computed: {
    ...mapGetters("Chapter", ["chapters"]),
  },
  async created() {
    let totalScore = 0;
    let globalCompletion = 0;
    await this.asyncForEach(this.chapters, async (chapter) => {
      let chapterWithScore = await this.getChapterScoreById(chapter.id);

      if (chapterWithScore.score) totalScore += chapterWithScore.score;
      if (chapterWithScore.completion)
        globalCompletion += chapterWithScore.completion;
    });
    this.starRating = totalScore / this.chapters.length;
    this.globalCompletion = Math.floor(globalCompletion / this.chapters.length);
  },
  methods: {
    ...mapActions("Chapter", ["getChapterScoreById"]),
    async getChapterScore(chapter_id) {
      this.current = null;
      this.selected = await this.getChapterScoreById(chapter_id);
    },
    async asyncForEach(array, callback) {
      for (let index = 0; index < array.length; index++) {
        await callback(array[index], index, array);
      }
    },
    handleClick(selected) {
      this.$router.push("/chapitre/" + selected.number);

      setTimeout(() => {
        this.$emit("close");
      }, 1000);
    },
  },
  // mounted()  {
  //   this.current = this.data[0];
  //   this.current.index =  1
  // }
};
</script>
