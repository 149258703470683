<template>
              <v-progress-circular v-if='data' class="percentage" :size="size" :rotate="-90" :color="style.main" :value="data.completion"></v-progress-circular>
</template>
<script>
import { mapActions } from 'vuex'
import styles from '@/assets/styles/utils/Variables.scss'
export default {
    
    props: {
        chapter:{
            type:String,
        },
        size:{
            type:Number,
            default: 45
        }
    },
    data() {
        return {
            data:null,
            style:null
        } 
    },
    async mounted() {
        this.data = await this.getChapterScoreById(this.chapter)
        this.style = styles
    },

    methods: {
    ...mapActions('Chapter', ['getChapterScoreById']),
    },
}
</script>