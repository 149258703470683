<template>
<section class="menu">
    <v-dialog
      v-model="dialog"
      scrollable
      max-width="650px"
      content-class="menu-modal"
      hide-overlay
      persistent
      :fullscreen="isMobile"
    >
    <v-card :color="isMobile ? 'white' : 'rgba(255,255,255, 0.95)'" class="intro-card d-flex flex-column align-center py-8">
      <div class="logo-container px-4 mb-5">
        <img src="@/assets/images/onv_logo.png" class="onv-logo ma-auto d-block" alt="">

      </div>
      <section class="menu-links mb-6 d-flex flex-column justify-space-around pt-4">
        <MenuItem class="mb-2" @change="changeChapter(chapter)" v-for='(chapter,i) in chapters' :key="chapter.id" :chapter="chapter" />
      </section>
      <section class="footer-menu d-flex justify-space-between mb-8">
        <img class="menu-icon" @click="openInfos = true" src="@/assets/images/icons/about.svg" style="cursor:pointer">
        <img class="menu-icon"  v-if="this.$route.name === 'chapitre'" @click="handleOpenLib" src="@/assets/images/icons/single-file.svg" style="cursor: pointer">
        <img class="menu-icon" v-else @click="openLibrary = true" src="@/assets/images/icons/library.svg" style="cursor:pointer">
        <img class="menu-icon" @click="openScore = true"  src="@/assets/images/icons/rewards.svg" style="cursor:pointer">
      </section>
      <transition name="fade">
        <Library @close="openLibrary = false" v-if="openLibrary" />
        <Score @close="openScore = false" v-if="openScore" />
        <UserInfos  @close="openInfos = false" v-if='openInfos'/>
      </transition>
    </v-card>
    </v-dialog>
    <div class="logos-bg ">
        <div class="logo-container py-1">
          <img src="@/assets/images/AL-ONV-Logo-min.jpg" alt="">
        </div>
        <div class="logo-container">
          <img src="@/assets/images/logo_action_logement.png" alt="">
        </div>
      </div>
  </section>
</template>
<script>
import Score from '@/components/Score.vue'
import UserInfos from '@/components/UserInfos.vue'
import Library from '@/components/Library.vue'
import MenuItem from '@/components/Menu_Item.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'Menu',
  components: {
    MenuItem,
    Score,
    UserInfos,
    Library
  },
  props: {
    isModal: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      openInfos:false,
      openLibrary:false,
      openScore:false,
      dialog: true,
    }
  },
  async created () {
    await this.$store.dispatch('Chapter/getChapterList')
  },
  computed: {
    ...mapGetters(['isMobile','appWidth']),
    chapters () {
      return this.$store.getters['Chapter/chapters']
    },
  },
  methods: {
    changeChapter (chapter) {
      if (this.isModal) this.$emit('close')
      if (this.$route.params.id == chapter.number) return
      this.$router.push({ name: 'chapitre', params: { id: chapter.number, chapter } })
    }
  },
}
</script>
